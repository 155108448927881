import { canUseDOM } from 'core/helpers';

export const checkPixelRatio = (exclude, type) => {
  if (canUseDOM()) {
    const pixelRatio = window.devicePixelRatio;

    const checkType = (outerType, innerType) => {
      if (outerType === 'forFolder') {
        return innerType[0];
      }

      return innerType[1];
    };

    const [X1, X2, X3] = [['x1', ''], ['x2', '@2x'], ['x3', '@3x']];

    if (pixelRatio > 1.5 && pixelRatio <= 2.5) {
      return checkType(type, X2);
    }

    if (pixelRatio > 2.5) {
      if (exclude === 'x3') {
        return checkType(type, X2);
      }

      return checkType(type, X3);
    }

    return checkType(type, X1);
  }

  return '';
};

export const createBasicPath = (path) => {
  const basicPath = path ? path.split(/(\.[a-z]{3,4})$/ig) : null;

  return basicPath && basicPath.length > 1 ? basicPath : null;
};
