import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { withCoreComponent } from 'core/hocs';
import { Banners as BannersCore } from 'core/components';

import { Carousel } from 'components/carousel/carousel';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { GA } from 'helpers/ga';
import { BannersCarouselPreloader } from './banners-carousel-preloader';
import { withPixelRatio } from '../../hocs/with-pixel-ratio';

import './banners-carousel.scss';

const prepareLink = (link, parent) => {
  if (!parent) {
    return null;
  }

  const regExp = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (regExp.test(link)) {
    return link;
  }

  return `https://${parent}${link.startsWith('/') ? link : `/${link}`}`;
};

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  useTransform: false,
  lazyLoad: 'ondemand',
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 2103,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

class BannersCarouselUI extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    pixelRatio: PropTypes.string.isRequired,
    parent: PropTypes.string,
  };

  static defaultProps = {
    items: null,
    parent: null,
  };

  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  getMobileImageUrl = ({ url1x, url2x, url3x }) => {
    const { pixelRatio } = this.props;

    if (pixelRatio === '@2x') {
      return url2x;
    }

    if (pixelRatio === '@3x') {
      return url3x;
    }

    return url1x;
  };

  getDesktopImageUrl = ({ url1x, url2x }) => {
    const { pixelRatio } = this.props;

    if (pixelRatio === '@2x' || pixelRatio === '@3x') {
      return url2x;
    }

    return url1x;
  };

  onClick = (e) => {
    e.stopPropagation();
    const { dataset: { link } } = e.currentTarget;

    if (link) {
      window.open(link, '_blank', 'noopener, noreferrer');
    }

    GA.event({
      category: 'navigation',
      label: 'banners-click',
    });
  };

  render() {
    const { items, isInProgress, parent } = this.props;
    const { isMounted } = this.state;

    return (
      <div className={classNames('banners-carousel mt-sm-2 mb-2_5', { 'is-one-item': items && items.length && items.length === 1 })}>
        {isMounted && !isInProgress && items
          ? (
            <Carousel
              settings={settings}
              iconRight={<IconArrowRight />}
              iconLeft={<IconArrowRight />}
            >
              {items.map(({
                id,
                title,
                cta,
                link,
                mobileImage,
                desktopImage,
                align,
              }) => {
                const preparedLink = prepareLink(link, parent);

                return (
                  <div
                    role="button"
                    tabIndex="0"
                    key={id}
                    className="banners-carousel-item pl-2 pl-sm-4 pt-3_5 position-relative"
                    draggable={false}
                    onClick={this.onClick}
                    onKeyPress={this.onClick}
                    data-link={preparedLink}
                  >
                    {!!title && (
                      <div className="banners-carousel-item-title mt-sm-4 h3 font-weight-bolder overflow-hidden">
                        {title}
                      </div>
                    )}

                    {!!cta && (
                      <Button
                        size="sm"
                        color="primary"
                        className="mt-3 mt-sm-7"
                        onClick={this.onClick}
                        data-link={preparedLink}
                      >
                        {cta}
                      </Button>
                    )}

                    <div
                      style={{
                        backgroundImage: `url("${this.getMobileImageUrl(mobileImage)}")`,
                        backgroundPositionX: align,
                      }}
                      className="banners-carousel-bg-mobile d-sm-none position-absolute"
                    />
                    <div
                      style={{
                        backgroundImage: `url("${this.getDesktopImageUrl(desktopImage)}")`,
                        backgroundPositionX: align,
                      }}
                      className="banners-carousel-bg-desktop d-none d-sm-block position-absolute"
                    />
                  </div>
                );
              })}
            </Carousel>
          )
          : <BannersCarouselPreloader />}
      </div>
    );
  }
}

export const BannersCarousel = withPixelRatio(withCoreComponent(BannersCore, BannersCarouselUI));
