import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withUpcomingGames, withInplayGames } from 'core/hocs';
import {
  TOP_EVENTS_TYPES,
  INPLAY_GAMES_TYPES,
  UPCOMING_GAMES_TYPES,
  INPLAY_GAMES_STORE_FIELDS,
  UPCOMING_GAMES_STORE_FIELDS,
  UPCOMING_GAMES_TIME_FILTERS,
} from 'core/constants';

import { BannersCarousel } from 'components/banners-carousel/banners-carousel';
import { TopEvents } from 'components/top-events/top-events';
import { InplayGames } from 'components/inplay-games/inplay-games';
import { UpcomingGames } from 'components/upcoming-games/upcoming-games';
import { NoBetsStub } from 'components/no-bets-stub/no-bets-stub';
import { PAGE_NAMES } from '../../constants';

export const HomeUI = ({
  inplayGames,
  upcomingGames,
  isInplayGamesInProgress,
  isUpcomingGamesInProgress,
  upcomingGamesTimeFilter,
}) => {
  const areNoBets = !(isInplayGamesInProgress || isUpcomingGamesInProgress)
  && !R.isNil(inplayGames) && !R.isNil(upcomingGames) && !(inplayGames.length + upcomingGames.length)
  && upcomingGamesTimeFilter === UPCOMING_GAMES_TIME_FILTERS.ALL_TIME;

  return (
    <div>
      <BannersCarousel />
      <NoBetsStub isShowed={areNoBets} isButtonHide />
      <div className={classNames({ 'd-none': areNoBets })}>
        <TopEvents coreProps={{ type: TOP_EVENTS_TYPES.ALL }} />
        <InplayGames coreProps={{ type: INPLAY_GAMES_TYPES.HOME }} pageName={PAGE_NAMES.HOME} className="mb-2_5" />
        <UpcomingGames coreProps={{ type: UPCOMING_GAMES_TYPES.ALL }} pageName={PAGE_NAMES.HOME} />
      </div>
    </div>
  );
};

HomeUI.propTypes = {
  inplayGames: PropTypes.arrayOf(PropTypes.shape()),
  upcomingGames: PropTypes.arrayOf(PropTypes.shape()),
  isInplayGamesInProgress: PropTypes.bool.isRequired,
  isUpcomingGamesInProgress: PropTypes.bool.isRequired,
  upcomingGamesTimeFilter: PropTypes.number.isRequired,
};

HomeUI.defaultProps = {
  inplayGames: null,
  upcomingGames: null,
};

export default withInplayGames(
  withUpcomingGames(
    HomeUI,
    UPCOMING_GAMES_TYPES.ALL,
    [
      UPCOMING_GAMES_STORE_FIELDS.ITEMS,
      UPCOMING_GAMES_STORE_FIELDS.IS_IN_PROGRESS,
      UPCOMING_GAMES_STORE_FIELDS.TIME_FILTER,
    ]
  ),
  INPLAY_GAMES_TYPES.HOME,
  [
    INPLAY_GAMES_STORE_FIELDS.ITEMS,
    INPLAY_GAMES_STORE_FIELDS.IS_IN_PROGRESS,
  ]
);
